import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFound from '@/views/NotFound.vue'
import library from '@/router/library'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/error/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/bibliotheek',
    name: 'Library',
    component: () => import(/* webpackChunkName: "library" */ '@/views/LibraryView.vue'),
    redirect() {
      return { name: 'ManualCategories' }
    },
    children: library
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 150,
        behavior: 'smooth',
      }
    }
    if (!savedPosition) {
      return { left: 0, top: 0 }
    }
    return savedPosition
  }
})

export default router
