import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'overzicht',
    name: 'LibraryOverview',
    component: () => import(/* webpackChunkName: "library" */ '@/views/library/OverviewView.vue')
  },
  {
    path: 'handleidingen',
    name: 'ManualsOverview',
    component: () => import(/* webpackChunkName: "library" */ '@/views/library/manual/CategoryView.vue'),
    children: [
      {
        path: '',
        name: 'ManualCategories',
        component: () => import(/* webpackChunkName: "library" */ '@/views/library/manual/category/BlockView.vue'),
      },
      {
        path: ':category',
        component: () => import(/* webpackChunkName: "library" */ '@/views/library/manual/category/DocsListView.vue'),
        children: [
          {
            path: ':title',
            name: 'ManualDocView',
            component: () => import(/* webpackChunkName: "library" */ '@/views/library/manual/category/docs/DocView.vue'),
            children: [
              {
                path: '',
                name: 'ManualPageView',
                component: () => import(/* webpackChunkName: "library" */ '@/views/library/manual/category/docs/doc/PageView.vue')
              },
            ]
          },
        ]
      },
    ]
  },
]

export default routes
